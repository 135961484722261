import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Box, Grid, Stack, Typography, Button } from '@mui/material'
import { useAuth } from '../context/AuthContext'
import { enqueueSnackbar } from 'notistack'
import PageHeading from '../components/PageHeading'
import { iconsObj } from '../icons/Icons'

import PrsResultsDialog from '../components/dialogs/PrsResultsDialog'
import { TargetDataResponse } from '../components/dialogs/TargetDataDialog'
import { ListItemWithAvatar } from '../components/buttons/TargetAndBaseDataButton'
import ForceGraph2DPlot, { Node, Link, NetworkPlotData } from '../components/plots/NetworkPlot'
import GeneProcessDrawer from '../components/GeneProcessDrawer'
import { Option } from '../components/forms/FormProps'
import FormInputComboBox from '../components/forms/FormInputComboBox'
import { useForm } from 'react-hook-form'
import { isNumber } from '@mui/x-data-grid/internals'
import { Analysis } from './AnalysisManager/types'
import { useQueryClient } from '@tanstack/react-query'

type ExtendedNode = Node & {
  level: number
}

type ProcessGraphDataResponse = {
  nodes: ExtendedNode[]
  links: Link[]
  go_annotations_no_hierarchy_ids: number[]
}

type Props = {
  analysisId: string
}

type GoAnnotationGwasVariantsData = number[]

export type GeneType = {
  id: number
  type: string
}

export type EvidenceType = {
  id: number
  type: string
}

type GoAnnotationResponse = {
  id: number
  go_id: string
  go_term_name: string
  go_domain_name: string
  go_level: string
  go_depth: string
}

const ProcessBasedAnalysis: React.FC = () => {
  const queryClient = useQueryClient()

  const clearAnalysisCache = () => {
    const queries = queryClient.getQueryCache().findAll()

    const matchingKeys = queries
      .filter(({ queryKey }) => {
        if (queryKey[0] !== 'analysis') {
          return false
        }
        const baseManagerKey = (queryKey[1] as { baseDataId?: number }).baseDataId
        const targetManagerKey = (queryKey[1] as { targetDataId?: number }).targetDataId

        if (baseManagerKey === analysis.base_data_manager.id && targetManagerKey === analysis.target_data_manager.id) {
          return true
        }
      })
      .map(({ queryKey }) => queryKey)
    console.log('Matching keys', matchingKeys)

    matchingKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key })
    })
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [anotationsIsLoading, setAnotationsIsLoading] = useState<boolean>(false)

  const [drawerOpen, setDrawerOpen] = useState(false)

  const [analysis, setAnalysis] = useState<Analysis>({} as Analysis)
  const [goAnnotation, setGoAnnotation] = useState<Option[]>([])
  const [goAnnotationUserInput, setGoAnnotationUserInput] = useState<string>('')
  const [goAnnotationIds, setGoAnnotationIds] = useState<number[]>([])
  const [goAnnotationGwasVariantsIds, setGoAnnotationGwasVariantsIds] = useState<number[]>([])
  const [goAnnotationSnpIds, setGoAnnotationSnpIds] = useState<number[]>([])
  const [showDialog, setShowDialog] = useState(false)
  const [selectedNodeName, setSelectedNodeName] = useState<string | null>(null)
  const [targetData, setTargetData] = useState<TargetDataResponse>({} as TargetDataResponse)
  const [processGraphData, setProcessGraphData] = useState<NetworkPlotData | undefined>(undefined)
  const [geneTypes, setGeneTypes] = useState<GeneType[]>([])
  const [evidenceTypes, setEvidenceTypes] = useState<EvidenceType[]>([])

  const [selectedGeneTypes, setSelectedGeneTypes] = useState<string[]>([])
  const [selectedEvidenceTypes, setSelectedEvidenceTypes] = useState<string[]>([])
  const [selectedGenesSwitchChecked, setSelectedGenesSwitchChecked] = useState<boolean>(true)
  const [selectedGeneRegionsSwitchChecked, setSelectedGeneRegionsSwitchChecked] = useState<boolean>(true)
  const [selectedGeneRegionsUpstreamSwitchChecked, setSelectedGeneRegionsUpstreamSwitchChecked] = useState<boolean>(true)
  const [selectedGeneRegionsDownstreamSwitchChecked, setSelectedGeneRegionsDownstreamSwitchChecked] = useState<boolean>(true)
  const [selectedHierarchySize, setSelectedHierarchySize] = useState<number>(12)
  const [annotation_ids, setAnnotationIds] = useState<number[]>([])

  const analysisId = Number(useParams<Props>().analysisId)

  const { backendRequest, logout } = useAuth()

  const { control } = useForm()

  useEffect(() => {
    setIsLoading(true)

    const getData = async () => {
      const responseAnalysis = await backendRequest({
        method: 'GET',
        endpoint: `/api/analyses-manager/${analysisId}`,
        requiresAuth: true,
      })

      const analysisData = responseAnalysis.data as Analysis | undefined

      const responseSnps = await backendRequest({
        method: 'GET',
        endpoint: `/api/base-data-manager/${analysisData?.base_data_manager.id}/snps`,
        requiresAuth: true,
      })

      const snpsIds = responseSnps.data as number[] | undefined
      const responseGoAnnotations = await backendRequest({
        method: 'POST',
        endpoint: '/api/go-annotations/network',
        requiresAuth: true,
        body: {
          gwas_variants_ids: snpsIds,
          selectedGeneTypes: selectedGeneTypes,
          selectedEvidenceTypes: selectedEvidenceTypes,
          selectedGenesSwitchChecked: selectedGenesSwitchChecked,
          selectedGeneRegionsSwitchChecked: selectedGeneRegionsSwitchChecked,
          selectedGeneRegionsUpstreamSwitchChecked: selectedGeneRegionsUpstreamSwitchChecked,
          selectedGeneRegionsDownstreamSwitchChecked: selectedGeneRegionsDownstreamSwitchChecked,
          selectedHierarchySize: selectedHierarchySize,
        },
      })

      const goAnnotationsData = responseGoAnnotations.data as ProcessGraphDataResponse | undefined

      const responseTargetData = await backendRequest({
        method: 'GET',
        endpoint: `/api/target-data-manager/assign-patients-and-classes/${analysisData?.target_data_manager.id}`,
        requiresAuth: true,
      })

      const targetData = responseTargetData.data as TargetDataResponse | undefined

      const responseGeneTypes = await backendRequest({
        method: 'GET',
        endpoint: '/api/genes/types',
        requiresAuth: true,
      })

      const geneTypes = responseGeneTypes.data as GeneType[]

      const responseEvidenceTypes = await backendRequest({
        method: 'GET',
        endpoint: '/api/genes/evidence-types',
        requiresAuth: true,
      })

      const evidenceTypes = responseEvidenceTypes.data as EvidenceType[]

      return {
        analysisStatus: responseAnalysis.status,
        analysisData: analysisData,
        snpsStatus: responseSnps.status,
        snpsData: snpsIds,
        goAnnotationsStatus: responseGoAnnotations.status,
        goAnnotationsData: goAnnotationsData,
        targetDataStatus: responseTargetData.status,
        targetData: targetData,
        geneTypesStatus: responseGeneTypes.status,
        geneTypes: geneTypes,
        evidenceTypesStatus: responseEvidenceTypes.status,
        evidenceTypes: evidenceTypes,
      }
    }
    getData()
      .then(
        ({
          analysisStatus,
          analysisData,
          snpsStatus,
          snpsData,
          goAnnotationsStatus,
          goAnnotationsData,
          targetDataStatus,
          targetData,
          geneTypesStatus,
          geneTypes,
          evidenceTypesStatus,
          evidenceTypes,
        }) => {
          if (analysisStatus === 200 && analysisData) {
            setAnalysis(analysisData)
          } else if (analysisStatus === 401) {
            logout()
          }
          if (snpsStatus === 200 && snpsData) {
            setGoAnnotationGwasVariantsIds(snpsData)
          }
          if (goAnnotationsStatus === 200 && goAnnotationsData) {
            const nodesResponse = goAnnotationsData.nodes.map((node) => {
              return {
                id: node.id,
                label: node.label,
                group: node.group,
              }
            })
            setProcessGraphData({ nodes: nodesResponse, links: goAnnotationsData.links })
            setAnnotationIds(goAnnotationsData.go_annotations_no_hierarchy_ids)
            setGoAnnotationIds(goAnnotationsData.nodes.map((node) => node.id))
            setGoAnnotation([])
          }
          if (targetDataStatus === 200 && targetData) {
            setTargetData(targetData)
          }
          if (geneTypesStatus === 200 && geneTypes) {
            setGeneTypes(geneTypes)
          }
          if (evidenceTypesStatus === 200 && evidenceTypes) {
            setEvidenceTypes(evidenceTypes)
          }
        }
      )
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    selectedGeneTypes,
    selectedEvidenceTypes,
    selectedGenesSwitchChecked,
    selectedGeneRegionsSwitchChecked,
    selectedGeneRegionsUpstreamSwitchChecked,
    selectedGeneRegionsDownstreamSwitchChecked,
    selectedHierarchySize,
  ])

  useEffect(() => {
    if (goAnnotationUserInput.trim() !== '') {
      const getData = async () => {
        const responseGoAnnotation = await backendRequest({
          method: 'POST',
          endpoint: '/api/go-annotations/full-text-search',
          requiresAuth: true,
          body: {
            go_annotation: goAnnotationUserInput,
            go_annotation_ids: goAnnotationIds,
          },
        })

        const goAnnotationData = responseGoAnnotation.data as GoAnnotationResponse[] | undefined

        return { status: responseGoAnnotation.status, data: goAnnotationData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            setGoAnnotation(
              response.data.map((goAnnotationItem) => ({
                id: goAnnotationItem.id,
                label: goAnnotationItem.go_term_name,
              }))
            )
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [goAnnotationUserInput, goAnnotationIds])

  const handleSelectGoAnnotation = async (option: Option) => {
    if (option) {
      if (isNumber(option.id)) {
        await onClickProcess(option.id, option.label)
        setSelectedNodeName(option.label)
        setShowDialog(true)
      }
    }
  }

  const handleOnClickProcess = async (node: Node) => {
    await onClickProcess(node.id, node.label)
    setSelectedNodeName(node.label)
    setShowDialog(true)
  }

  const handleDrawerDataSave = (drawerData: {
    selectedGeneTypes: string[]
    selectedEvidenceTypes: string[]
    selectedGenesSwitchChecked: boolean
    selectedGeneRegionsSwitchChecked: boolean
    selectedGeneRegionsUpstreamSwitchChecked: boolean
    selectedGeneRegionsDownstreamSwitchChecked: boolean
    selectedHierarchySize: number
  }) => {
    setSelectedGeneTypes(drawerData.selectedGeneTypes)
    setSelectedEvidenceTypes(drawerData.selectedEvidenceTypes)
    setSelectedGenesSwitchChecked(drawerData.selectedGenesSwitchChecked)
    setSelectedGeneRegionsSwitchChecked(drawerData.selectedGeneRegionsSwitchChecked)
    setSelectedGeneRegionsUpstreamSwitchChecked(drawerData.selectedGeneRegionsUpstreamSwitchChecked)
    setSelectedGeneRegionsDownstreamSwitchChecked(drawerData.selectedGeneRegionsDownstreamSwitchChecked)
    setSelectedHierarchySize(drawerData.selectedHierarchySize)
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }

    setDrawerOpen(open)
  }

  const onClickProcess = async (go_annotation_id: number, go_annotation_name: string) => {
    clearAnalysisCache()

    setGoAnnotationSnpIds([])
    setSelectedNodeName(go_annotation_name)
    setShowDialog(true)

    try {
      setAnotationsIsLoading(true)

      const response = await backendRequest({
        method: 'POST',
        endpoint: '/api/go-annotations/get-gwas-variants',
        requiresAuth: true,
        body: {
          go_annotation_id: go_annotation_id,
          go_annotation_ids: annotation_ids,
          gwas_variants_ids: goAnnotationGwasVariantsIds,
          selectedGeneTypes: selectedGeneTypes,
          selectedGenesSwitchChecked: selectedGenesSwitchChecked,
          selectedGeneRegionsSwitchChecked: selectedGeneRegionsSwitchChecked,
          selectedGeneRegionsUpstreamSwitchChecked: selectedGeneRegionsUpstreamSwitchChecked,
          selectedGeneRegionsDownstreamSwitchChecked: selectedGeneRegionsDownstreamSwitchChecked,
        },
      })

      if (response.status === 200) {
        setAnotationsIsLoading(false)

        const goAnnotationGwasVariantsData = response.data as GoAnnotationGwasVariantsData
        setGoAnnotationSnpIds(goAnnotationGwasVariantsData)
      } else if (response.status === 401) {
        logout()
      }
    } catch (error) {
      enqueueSnackbar(`An error occurred.`, { variant: 'error' })
    }
  }

  return (
    <React.Fragment>
      <Grid container direction='column' justifyContent='space-between' alignItems='stretch' height={1} pl={15} pr={8} mt={-4}>
        <Grid container item direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item md={6} xs={8}>
            <Box display='flex' alignItems='center' gap={2}>
              <PageHeading icon={iconsObj.ANALYSES}>Gene Process Based Analysis</PageHeading>
              <Typography variant='h6' id='analysis-name' textAlign='left'>
                {analysis?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={6} lg={6} xl={2.5} display='flex' justifyContent='flex-end'>
            <Button
              variant='outlined'
              startIcon={iconsObj.SETTINGS}
              disabled={geneTypes.length === 0 || evidenceTypes.length === 0}
              onClick={toggleDrawer(true)}
              sx={{
                color: 'black',
                borderColor: 'black',
                '&:hover': {
                  borderColor: 'black',
                },
              }}
            >
              Gene Process Settings
            </Button>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' item height={1}>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={3}>
            <Stack direction='row'>
              <ListItemWithAvatar icon={iconsObj.TARGET_DATA} primaryLabel='Target Data' secondaryLabel={analysis.target_data_manager?.name} />
              <ListItemWithAvatar icon={iconsObj.BASE_DATA} primaryLabel='Base Data' secondaryLabel={analysis.base_data_manager?.name} />
            </Stack>
          </Grid>
          <Grid item xs={4} md={6} lg={6} xl={3} display='flex' justifyContent='flex-end'>
            <FormInputComboBox
              id='new-analysis-go-annotation'
              label='Gene Process Name'
              name='goAnnotation'
              disabled={geneTypes.length === 0}
              options={goAnnotation}
              control={control}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                  setGoAnnotationUserInput(value)
                }
              }}
              onChange={(event, value) => {
                handleSelectGoAnnotation(value as Option)
              }}
            />
          </Grid>
        </Grid>
        <Grid container item direction='row' mt={3} justifyContent='space-between' alignItems='stretch' height={1} width={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant='h6' textAlign='left'>
              GO Process Visualization
            </Typography>
            <ForceGraph2DPlot data={processGraphData} isLoading={isLoading} onNodeClick={handleOnClickProcess} />
          </Grid>
        </Grid>
      </Grid>
      <GeneProcessDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        onSave={handleDrawerDataSave}
        geneTypes={geneTypes}
        evidenceTypes={evidenceTypes}
      />
      <PrsResultsDialog
        show={showDialog}
        setShow={setShowDialog}
        pageHeading={`Gene Process: ${selectedNodeName}`}
        baseDataName={analysis.base_data_manager?.name || ''}
        targetDataName={analysis.target_data_manager?.name || ''}
        snpIds={goAnnotationSnpIds}
        targetData={targetData}
        isLoading={isLoading || anotationsIsLoading}
        analysisId={analysisId}
        baseDataId={analysis.base_data_manager?.id}
      />
    </React.Fragment>
  )
}

export default ProcessBasedAnalysis
